import React, { useState } from 'react';
import { Button, Box, Typography, Snackbar } from '@mui/material';
import axios from 'axios';
import AuthService from '../services/auth.service';
import config from "../config";

const api = axios.create({
  baseURL: config.API_URL,
});


const UploadIngredients = ({ refreshIngredients }) => {
  const [file, setFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setSnackbarMessage('Please select a file first');
      setSnackbarOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = AuthService.getCurrentUser().accessToken; // Get the token
      const response = await api.post('/api/ingredients/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token, // Include the token in the headers
        },
      });

      setSnackbarMessage('File uploaded successfully');
      setSnackbarOpen(true);
      refreshIngredients(); // Call the refresh function
    } catch (error) {
      console.error('Error uploading file:', error);
      setSnackbarMessage('Failed to upload file');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ p: 3, border: '1px solid #ccc', borderRadius: '8px' }}>
      <Typography variant="h6" gutterBottom>
        Upload Ingredients Excel File
      </Typography>
      <input type="file" onChange={handleFileChange} />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        sx={{ mt: 3 }}
        disabled={!file}
      >
        Upload File
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default UploadIngredients;
