import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { AuthProvider, AuthContext } from "../src/contexts/AuthContext"
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import IngredientsList from "./components/IngredientsList";
import AddIngredient from './components/AddIngredient';
import UploadIngredients from "./components/UploadIngredients";
import PublicFormulaView from './components/PublicFormulaView';
import Sidebar from './components/Sidebar';
import { CssBaseline, Box, Toolbar, ThemeProvider, CircularProgress } from '@mui/material';
import theme from "./ui/theme";


function IngredientsListWrapper({ isEditing, editingFormulaId, setIsEditing, setEditingFormulaId, sidebarOpen }) {
  const location = useLocation();
  return (
    <IngredientsList
      key={location.pathname + location.search + (location.state?.isNewFormula ? 'new' : 'existing')}
      isEditing={isEditing}
      editingFormulaId={editingFormulaId}
      setIsEditing={setIsEditing}
      setEditingFormulaId={setEditingFormulaId}
      sidebarOpen={sidebarOpen}
    />
  );
}

const PrivateRoute = ({ children }) => {
  const { currentUser, loading } = useContext(AuthContext);

  if (loading) {
    return <CircularProgress />;
  }

  return currentUser ? children : <Navigate to="/login" />;
};

const AppContent = () => {
  const { currentUser, loading } = useContext(AuthContext);
  const [folders, setFolders] = useState([]);
  const [editingFormulaId, setEditingFormulaId] = useState(null); 
  const [isEditing, setIsEditing] = useState(false); 
  const [sidebarOpen, setSidebarOpen] = useState(true);


  // Handle edit formula logic and pass it to IngredientsList
  const handleEditFormula = (formulaId) => {
    setIsEditing(true);
    setEditingFormulaId(formulaId);
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(prevState => !prevState);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      {currentUser && (
        <Sidebar
            selectFormula={handleEditFormula}
            editFormula={handleEditFormula}
            folders={folders}
            isOpen={sidebarOpen}
            onToggle={handleSidebarToggle} 
          />
      )}
      <Box component="main" style={{ flexGrow: 1, padding: '24px' }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={currentUser ? <Navigate to="/home" /> : <Login />} />
          <Route path="/register" element={currentUser ? <Navigate to="/home" /> : <Register />} />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/ingredients"
            element={
              <PrivateRoute>
                <IngredientsListWrapper
                  isEditing={isEditing}
                  editingFormulaId={editingFormulaId}
                  setIsEditing={setIsEditing}
                  setEditingFormulaId={setEditingFormulaId}
                  sidebarOpen={sidebarOpen}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/addingredient"
            element={
              <PrivateRoute>
                <AddIngredient />
              </PrivateRoute>
            }
          />
          <Route
            path="/uploadingredients"
            element={
              <PrivateRoute>
                <UploadIngredients refreshIngredients={() => {}} />
              </PrivateRoute>
            }
          />
          <Route path="/formulas/view/:publicUrl" element={<PublicFormulaView />} />
        </Routes>
      </Box>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <AppContent />
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;