// src/services/auth.service.js
import axios from 'axios';
import config from '../config'

console.log('Config in auth.service:', config);
const API_URL = `${config.API_URL}/api/auth/`;
console.log('Auth Service API URL:', API_URL);

const register = (username, email, password) => {
  return axios.post(API_URL + "register", {
    username,
    email,
    password,
  });
};

const login = (email, password) => {
  console.log('Attempting login with URL:', `${API_URL}login`);
  return axios.post(API_URL + "login", {
    email,
    password,
  }).then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
};

export default AuthService;