import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { CircularProgress, Box, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import config from "../config";

const api = axios.create({
  baseURL: config.API_URL,
});


const PublicFormulaView = () => {
  const { publicUrl } = useParams();
  const [formula, setFormula] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inciNames, setInciNames] = useState([]);


  const fetchFormula = async () => {
    try {
      const response = await api.get(`/api/formulas/view/${publicUrl}`);
      console.log("Fetched Formula Data: ", response.data);
      setFormula(response.data);
    } catch (err) {
      console.error('Error fetching formula', err);
      setError('Failed to load formula. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFormula();
  }, [publicUrl]);

  const calculateInciNames = (ingredients, concentrations) => {
    const result = [];
    ingredients.forEach(ingredient => {
      const ingredientId = `ingredient-${ingredient.id}`;
      const concentration = parseFloat(concentrations[ingredientId]?.concentration) || 0;

      const updatedSubingredients = (ingredient.subingredients || []).map(sub => {
        const subPercentActive = parseFloat(sub.percent_active) || 0;
        const concentrationInEndFormula = (concentration * subPercentActive) / 100;
        return {
          inciName: sub.inci_name,
          concentration: concentrationInEndFormula.toFixed(2),
        };
      });

      if (ingredient.inci_name) {
        result.push({
          inciName: ingredient.inci_name,
          concentration: concentration.toFixed(2),
        });
      }

      result.push(...updatedSubingredients);
    });

    // Sort INCI names by concentration, descending
    result.sort((a, b) => parseFloat(b.concentration) - parseFloat(a.concentration));
    return result;
  };

  useEffect(() => {
    if (formula) {
      const inciNamesList = calculateInciNames(formula.data.ingredients, formula.data.concentrations);
      setInciNames(inciNamesList);
    }
  }, [formula]);

  const columns: GridColDef[] = [
    { field: "order", headerName: "Order", flex: 1, sortable: false, filterable: false },
    { field: "sap_code", headerName: "SAP Code", flex: 1 },
    { field: "trade_name", headerName: "Trade Name", flex: 1 },
    { field: "inci_name", headerName: "INCI Name", flex: 1 },
    { field: "einecs_no", headerName: "EINECS NO", flex: 1 },
    { field: "cas_no", headerName: "CAS No", flex: 1 },
    { field: "percent_active", headerName: "% Active", flex: 1 },
    { field: "manufacturer", headerName: "Manufacturer", flex: 1 },
    { field: "country_of_origin", headerName: "Country of Origin", flex: 1 },
    { field: "reach", headerName: "REACH", flex: 1 },
    { field: "function", headerName: "Function", flex: 1 },
    { field: "restrictions", headerName: "Restrictions", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "price", headerName: "Price", flex: 1 },
    { field: "vegan", headerName: "Vegan", flex: 1 },
    {
      field: "iso_16128_natural_origin_ingredient",
      headerName: "ISO 16128 Natural Origin Ingredient",
      flex: 1,
    },
    { field: "supplier", headerName: "Supplier", flex: 1 },
    {
      field: "no_animal_testing_declaration",
      headerName: "No Animal Testing Declaration",
      flex: 1,
    },
    {
      field: "sds_validity_3_years",
      headerName: "SDS (Validity 3 Years)",
      flex: 1,
    },
    { field: "organic", headerName: "Organic", flex: 1 },
    {
      field: "cosmos_certification",
      headerName: "COSMOS Certification",
      flex: 1,
    },
    { field: "gluten_free", headerName: "Gluten Free", flex: 1 },
    { field: "gmo", headerName: "GMO", flex: 1 },
    { field: "microplastic", headerName: "Microplastic", flex: 1 },
    { field: "rspo", headerName: "RSPO", flex: 1 },
    { field: "rspo_no", headerName: "RSPO №", flex: 1 },
    { field: "fairtrade", headerName: "Fairtrade", flex: 1 },
    {
      field: "origin_of_raw_material",
      headerName: "Origin of Raw Material",
      flex: 1,
    },
    {
      field: "microbiological_analysis",
      headerName: "Microbiological Analysis",
      flex: 1,
    },
    { field: "solubility", headerName: "Solubility", flex: 1 },
    {
      field: "concentration",
      headerName: "Concentration on formula in %",
      flex: 1,
      renderCell: (params) => {
        if (params.row.isSummary) {
          return (
            <span style={{ fontWeight: "bold" }}>
              {params.value.toFixed(2)}%
            </span>
          );
        }
      },
    },
    {
      field: "concentrationInEndFormula",
      headerName: "Concentration in end formula",
      flex: 1,
      renderCell: (params) => {
        if (params.row.isSummary) {
          return null; // No need to render for summary rows
        }
        const endFormulaConcentration = params.row.concentrationInEndFormula 
          ? parseFloat(params.row.concentrationInEndFormula).toFixed(2) 
          : 'N/A';
        return <span>{endFormulaConcentration}%</span>;
      },
    },
  ];
  
  // The rows useMemo is the same as in the previous version
  const rows = useMemo(() => {
    if (!formula) return [];
    
    const rows = [];
    formula.data.ingredients.forEach((ingredient, index) => {
      const ingredientId = `ingredient-${ingredient.id}`;
      const concentration = parseFloat(formula.data.concentrations[ingredientId]?.concentration || '0.00').toFixed(2);

      // Add main ingredient row
      rows.push({
        ...ingredient,
        id: `${ingredient.id}`,
        parentId: null,
        order: index + 1,
        concentration,
        concentrationInEndFormula: concentration,
      });

      // Add subingredients rows, ensure they are correctly connected
      (ingredient.subingredients || []).forEach((sub) => {
        rows.push({
          ...sub,
          id: `${ingredient.id}-${sub.id}`, // Combine parent ID with sub-ingredient ID
          parentId: `${ingredient.id}`,
          concentrationInEndFormula: parseFloat(sub.concentrationInEndFormula || '0.00').toFixed(2),
        });
      });
    });

    return rows;
  }, [formula]);
  
  

  useEffect(() => {
    if (rows && rows.length > 0) {
      console.log('Final Rows passed to DataGrid:', rows);
    } else {
      console.warn('Rows not ready yet or empty');
    }
  }, [rows]);

  // Checking rows data
  useEffect(() => {
    rows.forEach((row) => {
      if (!row.id) {
        console.error('Row is missing an id:', row);
      }
      if (!row.concentration && row.concentration !== 0) {
        console.error('Row is missing concentration:', row);
      }
      if (!row.concentrationInEndFormula && row.concentrationInEndFormula !== 0) {
        console.error('Row is missing concentrationInEndFormula:', row);
      }
    });
  }, [rows]);

  const getTreeDataPath = (row) => {
    if (!row) return [];
    
    if (row.parentId) {
      const parent = rows.find((item) => item.id === row.parentId);
      return parent ? [parent.trade_name, row.trade_name || row.inci_name] : [row.trade_name || row.inci_name];
    }

    return [row.trade_name || row.inci_name];
  };

  const groupingColDef = {
    headerName: "",
    width: 50,
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!formula) return <div>No formula found.</div>;

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        {formula.name}
      </Typography>
      <Box sx={{ height: 500, width: '100%' }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          treeData
          getTreeDataPath={getTreeDataPath}
          disableColumnReorder
          groupingColDef={groupingColDef}
        />
      </Box>
      <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
        <Typography variant="h6">INCI Names</Typography>
        <ul>
          {inciNames.map((item, index) => (
            <li key={index}>
              {item.inciName} - {item.concentration}%
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default PublicFormulaView;