// src/components/Register.jsx
import React, { useState } from 'react';
import AuthService from '../services/auth.service';
import { Box, TextField, Button, Typography } from '@mui/material';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleRegister = async () => {
    try {
      const response = await AuthService.register(username, email, password);
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || 'Registration failed');
    }
  };

  return (
    <Box>
      <Typography variant="h4">Register</Typography>
      <TextField label="Username" variant="outlined" fullWidth margin="normal" value={username} onChange={(e) => setUsername(e.target.value)} />
      <TextField label="Email" variant="outlined" fullWidth margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} />
      <TextField label="Password" type="password" variant="outlined" fullWidth margin="normal" value={password} onChange={(e) => setPassword(e.target.value)} />
      {message && <Typography color="error">{message}</Typography>}
      <Button variant="contained" color="primary" onClick={handleRegister}>Register</Button>
    </Box>
  );
};

export default Register;
