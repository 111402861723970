import React, { useEffect, useState, useContext } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Collapse,
  Box,
  Menu,
  MenuItem,
  Avatar
} from "@mui/material";
import {
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
  Delete as DeleteIcon,
  Edit as EditIcon,
  AccountCircle
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../contexts/AuthContext"
import FolderModule from "./FolderModule";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import config from "../config";


const api = axios.create({
  baseURL: config.API_URL,
});


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const Sidebar = ({ selectFormula, openModal, editFormula, isOpen, onToggle, children }) => {
  const [folders, setFolders] = useState([]);
  const [expandedFolderId, setExpandedFolderId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { currentUser, logout } = useContext(AuthContext);

  const fetchFolders = async () => {
    try {
      const response = await api.get("/api/folders", {
        headers: { "x-access-token": currentUser.accessToken },
      });
      setFolders(response.data);
    } catch (error) {
      console.error("Error fetching folders", error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchFolders();
    }
  }, [currentUser]);

  const handleDrawerToggle = () => {
    onToggle();  // Call the onToggle function passed as a prop
  };


  const handleFolderClick = (folderId) => {
    setExpandedFolderId(expandedFolderId === folderId ? null : folderId);
  };

  const handleDeleteFormula = async (formulaId) => {
    if (window.confirm("Are you sure you want to delete this formula?")) {
      try {
        await api.delete(`/api/formulas/${formulaId}`, {
          headers: {
            "x-access-token": currentUser.accessToken,
          },
        });
        fetchFolders();
      } catch (error) {
        console.error("Error deleting formula", error);
      }
    }
  };

  const handleEditFormula = (formulaId) => {
    editFormula(formulaId);
    navigate("/ingredients", { state: { isNewFormula: false } });
  };

  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };


  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" style={{ zIndex: 1202, backgroundColor:'#24292e' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            style={{ marginRight: "16px" }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/home"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            INCI Navigator
          </Typography>
          <div style={{ flexGrow: 1 }} />
        
        <IconButton
          color="inherit"
          onClick={handleUserMenuOpen}
          edge="end"
          style={{ marginLeft: "auto" }} // This ensures it stays on the right side
        >
          <AccountCircle />
        </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleUserMenuClose}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'rgb(18, 22, 33)',
            color: '#fff',
          },
        }}
        variant="persistent"
        anchor="left"
        open={isOpen}
      >
        <Toolbar />
        <Box sx={{padding: '15px', borderBottom:'1px solid white'}}>
        <Typography>Saved Formulas</Typography>
        </Box>
        <div style={{ overflow: "auto" }}>
          <List>
            {folders.map((folder) => (
              <div key={folder.id}>
                <ListItem button sx={{'&:hover': {
                            backgroundColor: 'rgba(192,192,192,0.2)', 
                          }}} onClick={() => handleFolderClick(folder.id)}>
                  <ListItemText primary={folder.name} />
                  {expandedFolderId === folder.id ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={expandedFolderId === folder.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {folder.formulas &&
                      folder.formulas.map((formula) => (
                        <ListItem key={formula.id} sx={{ 
                          pl: 4,
                          '&:hover': {
                            backgroundColor: 'rgba(192,192,192,0.2)', 
                          }
                        }}>
                          <ListItemText
                              primary={formula.name}
                              onClick={() => {
                                selectFormula(formula.id);
                                navigate("/ingredients", { state: { isNewFormula: false } });
                              }}
                            />
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => handleEditFormula(formula.id)}
                            style={{color:'#fff'}}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteFormula(formula.id)}
                            style={{color:'#fff'}}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
                <Divider />
              </div>
            ))}
          </List>
        </div>
      </Drawer>
      <Main open={isOpen}>
        <Toolbar />
        {children}
      </Main>
      <FolderModule
        open={openModal}
        onClose={() => {
          /* close modal logic */
        }}
        folders={folders}
        onSave={(folderId, formulaName) => {
          /* save formula logic */
        }}
        refreshFolders={fetchFolders}
      />
    </div>
  );
};

export default Sidebar;
