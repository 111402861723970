const isProd = process.env.NODE_ENV === 'production';

const config = {
  API_URL: isProd
    ? 'https://backend-inci-navigator-9ea5233e23dc.herokuapp.com'
    : process.env.REACT_APP_API_URL || 'http://localhost:8080'
};

console.log('Environment:', process.env.NODE_ENV);
console.log('Configured API_URL:', config.API_URL);

export default config;