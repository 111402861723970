import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AuthService from '../services/auth.service';
import { Modal, Paper, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material';
import config from "../config";

const api = axios.create({
  baseURL: config.API_URL,
});

const FolderModule = ({ open, onClose, folders, onSave, refreshFolders, isEditing, formulaName: initialFormulaName }) => {
  const [selectedFolder, setSelectedFolder] = useState("");
  const [folderName, setFolderName] = useState("");
  const [formulaName, setFormulaName] = useState(initialFormulaName || "");

  useEffect(() => {
    if (isEditing) {
      setFormulaName(initialFormulaName || ""); // Set formula name to the initial value if editing
    } else {
      setFormulaName(""); // Clear the formula name if not editing
    }
  }, [isEditing, initialFormulaName]);

  const handleSave = async () => {
    try {
      let folderIdToUse = selectedFolder;

      // Create a new folder if folderName is provided and selectedFolder is empty
      if (!selectedFolder && folderName) {
        const response = await api.post(
          "/api/folders",
          { name: folderName },
          { headers: { "x-access-token": AuthService.getCurrentUser().accessToken } }
        );
        folderIdToUse = response.data.id;

        // Refresh folders after creating a new one
        refreshFolders();
      }

      // Validate that we have a folderId and formula name
      if (!folderIdToUse) {
        alert("Please select or create a folder.");
        return;
      }

      if (!formulaName) {
        alert("Please provide a formula name.");
        return;
      }

      // Call the onSave method passed in the props to save or update the formula
      onSave(Number(folderIdToUse), formulaName, isEditing);  // Pass folderId to onSave
    } catch (error) {
      console.error("Error creating folder or saving formula:", error);
      alert("Failed to create folder or save formula.");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ margin: 'auto', padding: 4, width: 400, top: '30%', position: 'relative' }}>
        <Typography variant="h6" gutterBottom>
          {isEditing ? "Edit Formula" : "Save Formula"}
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Select Folder</InputLabel>
          <Select value={selectedFolder} onChange={(e) => setSelectedFolder(e.target.value)}>
            {folders.map((folder) => (
              <MenuItem key={folder.id} value={folder.id}>
                {folder.name}
              </MenuItem>
            ))}
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Or Create New Folder"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          margin="normal"
          disabled={isEditing}  // Disable this field if editing an existing formula
        />
        <TextField
          fullWidth
          label="Formula Name"
          value={formulaName}
          onChange={(e) => setFormulaName(e.target.value)}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ mt: 2 }}
        >
          {isEditing ? "Update Formula" : "Save"}
        </Button>
      </Paper>
    </Modal>
  );
};

export default FolderModule;
