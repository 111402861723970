import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridFooterContainer,
  GridFooter,
} from "@mui/x-data-grid-pro";
import axios from "axios";
import {
  Box,
  TextField,
  CircularProgress,
  Autocomplete,
  IconButton,
  Typography,
  Button,
  Paper,
  ButtonGroup,
  Snackbar,
  Popper,
} from "@mui/material";
import {
  ArrowUpward,
  ArrowDownward,
  Share,
  Tooltip,
} from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import debounce from "lodash.debounce";
import AuthService from "../services/auth.service";
import FolderModule from "./FolderModule";
import { useTheme } from "@mui/material";
import * as XLSX from "xlsx";
import config from "../config";

const api = axios.create({
  baseURL: config.API_URL,
});

// // Define drawerWidth here
// const drawerWidth = 240;

const IngredientsList = ({
  isEditing,
  editingFormulaId,
  setIsEditing,
  setEditingFormulaId,
  sidebarOpen,
}) => {
  const [ingredients, setIngredients] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [subIngredientsMap, setSubIngredientsMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [concentrations, setConcentrations] = useState({});
  const [renderKey, setRenderKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [formulaName, setFormulaName] = useState("");
  const [formulas, setFormulas] = useState([]);
  const [loadingFormulas, setLoadingFormulas] = useState(false);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editingFolderId, setEditingFolderId] = useState(null);
  const [inciNames, setInciNames] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [publicUrl, setPublicUrl] = useState(null);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  // const [sidebarOpen, setSidebarOpen] = useState(true);

  // Function to reset the component state
  const resetState = useCallback(() => {
    setSelectedIngredients([]);
    setConcentrations({});
    setSubIngredientsMap({});
    setFormulaName("");
    setIsEditing(false);
    setEditingFormulaId(null);
    setRenderKey((prevKey) => prevKey + 1);
  }, [setIsEditing, setEditingFormulaId]);

  // UseEffect to handle new formula creation or editing
  useEffect(() => {
    if (location.state?.isNewFormula) {
      // console.log("NEW Resetting state for new formula");
      resetState();
      // Clear the location state to prevent unexpected resets
      navigate(location.pathname, { replace: true, state: {} });
    } else if (isEditing && editingFormulaId) {
      // console.log("NEW Loading formula for editing");
      handleLoadFormula(editingFormulaId);
    }
  }, [
    location.state,
    resetState,
    navigate,
    location.pathname,
    isEditing,
    editingFormulaId,
  ]);

  const handleEditFormula = (formulaId) => {
    setIsEditing(true);
    setEditingFormulaId(formulaId);

    // Load the formula data into the component
    handleLoadFormula(formulaId);
  };

  // Fetching all data
  const fetchAllData = useCallback(async () => {
    setLoading(true);
    try {
      const token = AuthService.getCurrentUser()?.accessToken;
      if (!token) {
        throw new Error("No token found");
      }

      const headers = { "x-access-token": token };

      // Fetch Ingredients
      const ingredientResponse = await api.get("/api/ingredients", { headers });
      setIngredients(ingredientResponse.data);

      // Fetch Subingredients
      const subIngredientResponse = await api.get(
        "/api/ingredients/allsubingredients",
        { headers }
      );
      const map = {};
      subIngredientResponse.data.forEach((sub) => {
        if (!map[sub.ingredient_id]) {
          map[sub.ingredient_id] = [];
        }
        map[sub.ingredient_id].push(sub);
      });
      setSubIngredientsMap(map);

      // Fetch Folders
      const folderResponse = await api.get("/api/folders", { headers });
      setFolders(folderResponse.data);

      // Fetch Formulas
      const formulaResponse = await api.get("/api/formulas", { headers });
      setFormulas(formulaResponse.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, []);

  // UseEffect for fetching all data
  useEffect(() => {
    fetchAllData();
  }, []);

  // Debugging useEffect
  // useEffect(() => {
  //   console.log("NEW Component re-rendered");
  //   console.log("NEW Selected Ingredients:", selectedIngredients);
  //   console.log("NEW Concentrations:", concentrations);
  //   console.log("NEW SubIngredients Map:", subIngredientsMap);
  // }, [selectedIngredients, concentrations, subIngredientsMap]);

  // Load formula for editing
  useEffect(() => {
    if (isEditing && editingFormulaId) {
      handleLoadFormula(editingFormulaId);
    }
  }, [isEditing, editingFormulaId]);

  //Search function for ingredeints
  const handleSearch = async (event, value) => {
    if (value.length < 3) {
      setSearchResults([]);
      return;
    }

    setSearchLoading(true);
    try {
      const response = await api.get(`/api/ingredients/search?query=${value}`, {
        headers: {
          "x-access-token": AuthService.getCurrentUser().accessToken,
        },
      });
      setSearchResults(response.data);
      setSearchLoading(false);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchLoading(false);
    }
  };

  //Adding Ingredeint to table
  const handleAddIngredient = (event, value) => {
    if (value && !selectedIngredients.some((i) => i.id === value.id)) {
      setSelectedIngredients([...selectedIngredients, value]);
    }
  };

  // Fetching Formulas from DB
  const fetchFormulas = async () => {
    setLoadingFormulas(true);
    try {
      const response = await api.get("/api/formulas", {
        headers: { "x-access-token": AuthService.getCurrentUser().accessToken },
      });
      setFormulas(response.data);
    } catch (error) {
      console.error("Error fetching formulas:", error);
    }
    setLoadingFormulas(false);
  };

  useEffect(() => {
    fetchFormulas();
  }, []);

  const handleGeneratePublicUrl = async () => {
    if (!editingFormulaId) {
      setSnackbarMessage("No formula selected for sharing.");
      setSnackbarOpen(true);
      return;
    }

    try {
      const token = AuthService.getCurrentUser().accessToken;
      const response = await api.post(
        `/api/formulas/${editingFormulaId}/publicUrl`,
        {},
        {
          headers: { "x-access-token": token },
        }
      );
      setPublicUrl(
        `${window.location.origin}/formulas/view/${response.data.publicUrl}`
      );
      setSnackbarMessage("Public URL generated. Click to copy.");
      setSnackbarOpen(true);
    } catch (err) {
      console.error("Error generating public URL", err);
      setSnackbarMessage("Failed to generate public URL.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Saving Formulas to DB
  const handleSaveFormula = async (folderId, formulaName) => {
    try {
      const updatedIngredients = selectedIngredients.map((ingredient) => {
        const concentration =
          parseFloat(
            concentrations[`ingredient-${ingredient.id}`]?.concentration
          ) || 0;
        const updatedSubingredients = (
          subIngredientsMap[ingredient.id] || []
        ).map((sub) => {
          const subPercentActive = parseFloat(sub.percent_active) || 0;
          const concentrationInEndFormula =
            (concentration * subPercentActive) / 100;
          return {
            ...sub,
            concentrationInEndFormula: concentrationInEndFormula.toFixed(2),
          };
        });
        return { ...ingredient, subingredients: updatedSubingredients };
      });

      const formulaData = {
        name: formulaName || formulaName,
        folderId: folderId || selectedFolder,
        data: { ingredients: updatedIngredients, concentrations },
      };

      if (isEditing && editingFormulaId) {
        // Update the existing formula
        await api.put(`/api/formulas/${editingFormulaId}`, formulaData, {
          headers: {
            "x-access-token": AuthService.getCurrentUser().accessToken,
          },
        });
        alert("Formula updated successfully!");
      } else {
        // Save as a new formula
        await api.post("/api/formulas", formulaData, {
          headers: {
            "x-access-token": AuthService.getCurrentUser().accessToken,
          },
        });
        alert("Formula saved successfully!");
      }

      fetchAllData();
    } catch (error) {
      console.error("Error saving formula:", error);
      alert("Failed to save formula.");
    } finally {
      setOpenModal(false);
      setIsEditing(false);
      setEditingFormulaId(null);
    }
  };

  //Lodaing Formulas from DB
  const handleLoadFormula = async (formulaId) => {
    try {
      setLoadingFormulas(true);
      const response = await api.get(`/api/formulas/${formulaId}`, {
        headers: {
          "x-access-token": AuthService.getCurrentUser().accessToken,
        },
      });
      const { ingredients, concentrations } = response.data.data;

      const newSubIngredientsMap = ingredients.reduce((acc, ingredient) => {
        acc[ingredient.id] = ingredient.subingredients || [];
        return acc;
      }, {});

      setSelectedIngredients(ingredients);
      setConcentrations(concentrations);
      setSubIngredientsMap(newSubIngredientsMap);

      setTimeout(() => {
        // console.log("After update - selectedIngredients:", ingredients);
        // console.log("After update - concentrations:", concentrations);
        // console.log("After update - subIngredientsMap:", newSubIngredientsMap);
      }, 0);
    } catch (error) {
      console.error("Error loading formula:", error);
      alert("Failed to load formula.");
    } finally {
      setLoadingFormulas(false);
    }
  };

  // useEffect(() => {
  //   console.log("States updated, re-rendering...");
  //   setRenderKey((prevKey) => prevKey + 1);
  // }, [selectedIngredients, concentrations, subIngredientsMap]);

  //Moving ingredeints in table
  const moveRow = (fromIndex, toIndex) => {
    const updatedSelectedIngredients = [...selectedIngredients];
    const [movedItem] = updatedSelectedIngredients.splice(fromIndex, 1);
    updatedSelectedIngredients.splice(toIndex, 0, movedItem);

    setSelectedIngredients(updatedSelectedIngredients);
  };

  //Function for total % of Ingredeints
  const handleConcentrationBlur = (id, event) => {
    const value = event.target.value;
    // console.log(`Handling blur for ${id} with value ${value}`);
    debouncedHandleConcentrationChange(id, value);
  };

  //Debounce %
  const debouncedHandleConcentrationChange = useCallback(
    debounce((id, value) => {
      setConcentrations((prev) => {
        const numericValue = parseFloat(value);
        if (isNaN(numericValue)) return prev;

        const newConcentrations = {
          ...prev,
          [id]: {
            ...prev[id],
            concentration: numericValue,
          },
        };

        const totalConcentration = Object.values(newConcentrations).reduce(
          (sum, current) => sum + (current.concentration || 0),
          0
        );

        if (totalConcentration > 100) {
          setErrorMessage("Total concentration exceeds 100%");
        } else {
          setErrorMessage("");
        }

        return newConcentrations;
      });
    }, 300),
    []
  );

  //Summary Row %
  const summaryRow = (rows) => {
    const totalConcentration = Math.min(
      rows.reduce((acc, row) => {
        return acc + (row.concentration || 0);
      }, 0),
      100
    );

    return [
      {
        id: "summary-row",
        concentration: totalConcentration,
        isSummary: true,
      },
    ];
  };

  // Fetching Tree data for subIngredients
  const getTreeData = () => {
    const treeData = [];
    let totalConcentration = 0;

    selectedIngredients.forEach((ingredient, index) => {
      const ingredientId = `ingredient-${ingredient.id}`;
      const concentration =
        parseFloat(concentrations[ingredientId]?.concentration) || 0;
      totalConcentration += concentration;

      treeData.push({
        ...ingredient,
        id: ingredientId,
        parentId: null,
        order: index + 1,
        concentration: concentration,
        concentrationInEndFormula: concentration.toFixed(2),
        subingredients: subIngredientsMap[ingredient.id] || [],
      });

      const subs = subIngredientsMap[ingredient.id] || [];
      subs.forEach((sub) => {
        const subPercentActive = parseFloat(sub.percent_active) || 0;
        const concentrationInEndFormula =
          (concentration * subPercentActive) / 100;

        treeData.push({
          ...sub,
          id: `subingredient-${sub.id}`,
          parentId: ingredientId,
          order: "",
          concentration: subPercentActive,
          concentrationInEndFormula: concentrationInEndFormula.toFixed(2),
        });
      });
    });

    // console.log("Tree Data:", treeData);
    // console.log("Total Concentration:", totalConcentration);

    return { treeData, totalConcentration: Math.min(totalConcentration, 100) };
  };

  const { treeData, totalConcentration } = useMemo(() => {
    const { treeData, totalConcentration } = getTreeData();
    const summary = [
      {
        id: "summary-row",
        concentration: totalConcentration,
        isSummary: true,
      },
    ];

    // console.log("Final Tree Data:", [...treeData, ...summary]);
    // console.log("Final Total Concentration:", totalConcentration);

    return {
      treeData: [...treeData, ...summary],
      totalConcentration,
    };
  }, [selectedIngredients, concentrations, subIngredientsMap]);

  // Watch for state changes and log them
  useEffect(() => {
    // console.log("Selected Ingredients updated:", selectedIngredients);
  }, [selectedIngredients]);

  useEffect(() => {
    // console.log("Concentrations updated:", concentrations);
  }, [concentrations]);

  useEffect(() => {
    // console.log("SubIngredients Map updated:", subIngredientsMap);
  }, [subIngredientsMap]);

  //Calculating the total % of ingredeints
  const calculateEndFormulaConcentration = (row) => {
    // console.log("Calculating concentration for row:", row);

    if (!row) {
      return "0.00";
    }
    if (!row.parentId) {
      // console.log(
      //   `Returning top-level concentration for ${row.trade_name}: ${row.concentrationInEndFormula}`
      // );
      return row.concentrationInEndFormula || "0.00";
    }

    const parentId = row.parentId;
    const parentRow = treeData.find((item) => item.id === parentId);
    if (!parentRow) {
      console.warn(`Parent row with id ${parentId} not found.`);
      return "0.00";
    }

    const parentConcentration = parseFloat(parentRow.concentration) || 0;
    const percentActive = parseFloat(row.percent_active) || 0;
    const endFormulaConcentration = (parentConcentration * percentActive) / 100;

    // console.log(
    //   `End formula concentration for ${
    //     row.inci_name || row.trade_name
    //   }: ${endFormulaConcentration.toFixed(2)}`
    // );

    return endFormulaConcentration.toFixed(2);
  };

  // Calculate total concentration and sort ingredients when it reaches 100%
  useEffect(() => {
    const totalConcentration = Object.values(concentrations).reduce(
      (sum, item) => sum + (item.concentration || 0),
      0
    );

    if (totalConcentration === 100) {
      const inciConcentrationMap = {};

      selectedIngredients.forEach((ingredient) => {
        const concentration =
          parseFloat(
            concentrations[`ingredient-${ingredient.id}`]?.concentration
          ) || 0;

        if (ingredient.inci_name) {
          if (inciConcentrationMap[ingredient.inci_name]) {
            inciConcentrationMap[ingredient.inci_name] += concentration;
          } else {
            inciConcentrationMap[ingredient.inci_name] = concentration;
          }
        }

        const updatedSubingredients = (
          subIngredientsMap[ingredient.id] || []
        ).forEach((sub) => {
          const subPercentActive = parseFloat(sub.percent_active) || 0;
          const concentrationInEndFormula =
            (concentration * subPercentActive) / 100;

          if (inciConcentrationMap[sub.inci_name]) {
            inciConcentrationMap[sub.inci_name] += concentrationInEndFormula;
          } else {
            inciConcentrationMap[sub.inci_name] = concentrationInEndFormula;
          }
        });
      });

      const sortedInciNames = Object.entries(inciConcentrationMap)
        .map(([inciName, concentration]) => ({
          inciName,
          concentration: concentration.toFixed(2),
        }))
        .sort(
          (a, b) => parseFloat(b.concentration) - parseFloat(a.concentration)
        );

      setInciNames(sortedInciNames);
    } else {
      setInciNames([]);
    }
  }, [concentrations, selectedIngredients, subIngredientsMap]);

  //Column Definitions
  const columns: GridColDef[] = [
    {
      field: "order",
      headerName: "Order",
      width: 50,
      sortable: false,
      filterable: false,
    },
    { field: "sap_code", headerName: "SAP Code", flex: 1 },
    { field: "trade_name", headerName: "Trade Name", flex: 1 },
    { field: "inci_name", headerName: "INCI Name", flex: 1 },
    { field: "einecs_no", headerName: "EINECS NO", flex: 1 },
    { field: "cas_no", headerName: "CAS No", flex: 1 },
    { field: "percent_active", headerName: "% Active", flex: 1 },
    { field: "manufacturer", headerName: "Manufacturer", flex: 1 },
    { field: "country_of_origin", headerName: "Country of Origin", flex: 1 },
    { field: "reach", headerName: "REACH", flex: 1 },
    { field: "function", headerName: "Function", flex: 1 },
    { field: "restrictions", headerName: "Restrictions", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "price", headerName: "Price", flex: 1 },
    { field: "vegan", headerName: "Vegan", flex: 1 },
    {
      field: "iso_16128_natural_origin_ingredient",
      headerName: "ISO 16128 Natural Origin Ingredient",
      flex: 1,
    },
    { field: "supplier", headerName: "Supplier", flex: 1 },
    {
      field: "no_animal_testing_declaration",
      headerName: "No Animal Testing Declaration",
      flex: 1,
    },
    {
      field: "sds_validity_3_years",
      headerName: "SDS (Validity 3 Years)",
      flex: 1,
    },
    { field: "organic", headerName: "Organic", flex: 1 },
    {
      field: "cosmos_certification",
      headerName: "COSMOS Certification",
      flex: 1,
    },
    { field: "gluten_free", headerName: "Gluten Free", flex: 1 },
    { field: "gmo", headerName: "GMO", flex: 1 },
    { field: "microplastic", headerName: "Microplastic", flex: 1 },
    { field: "rspo", headerName: "RSPO", flex: 1 },
    { field: "rspo_no", headerName: "RSPO №", flex: 1 },
    { field: "fairtrade", headerName: "Fairtrade", flex: 1 },
    {
      field: "origin_of_raw_material",
      headerName: "Origin of Raw Material",
      flex: 1,
    },
    {
      field: "microbiological_analysis",
      headerName: "Microbiological Analysis",
      flex: 1,
    },
    { field: "solubility", headerName: "Solubility", flex: 1 },
    {
      field: "concentration",
      headerName: "Concentration on formula in %",
      flex: 1,
      renderCell: (params) => {
        if (params.row.isSummary) {
          return (
            <span style={{ fontWeight: "bold" }}>
              {params.value.toFixed(2)}%
            </span>
          );
        }
        if (params.row.parentId) {
          return <span>{params.value.toFixed(2)}%</span>;
        }
        return (
          <TextField
            variant="outlined"
            size="small"
            defaultValue={concentrations[params.row.id]?.concentration || ""}
            onBlur={(e) => handleConcentrationBlur(params.row.id, e)}
          />
        );
      },
    },
    {
      field: "concentrationInEndFormula",
      headerName: "Concentration in end formula",
      flex: 1,
      renderCell: (params) => {
        if (params.row.isSummary) {
          return null;
        }
        const endFormulaConcentration = calculateEndFormulaConcentration(
          params.row
        );
        return <span>{endFormulaConcentration}%</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        if (params.row.isSummary) {
          return null;
        }
        const index = selectedIngredients.findIndex(
          (ingredient) => `ingredient-${ingredient.id}` === params.row.id
        );
        return (
          <Box>
            {index > 0 && (
              <IconButton
                aria-label="move up"
                size="small"
                onClick={() => moveRow(index, index - 1)}
              >
                <ArrowUpward fontSize="inherit" />
              </IconButton>
            )}
            {index < selectedIngredients.length - 1 && (
              <IconButton
                aria-label="move down"
                size="small"
                onClick={() => moveRow(index, index + 1)}
              >
                <ArrowDownward fontSize="inherit" />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  const getTreeDataPath = (row) => {
    if (row.parentId) {
      const parent = treeData.find((item) => item.id === row.parentId);
      return parent
        ? [parent.trade_name, row.trade_name || row.inci_name]
        : [row.trade_name || row.inci_name];
    }
    return [row.trade_name || row.inci_name];
  };

  const handleExportToExcel = () => {
    const worksheetData = [];
    const merge = [];
    let currentRow = 1; // Start at 1 to account for header row

    const calculateEndFormulaConcentration = (row, parentConcentration) => {
      const percentActive = parseFloat(row.percent_active) || 0;
      return ((parentConcentration * percentActive) / 100).toFixed(4);
    };

    treeData.forEach((row) => {
      if (!row.isSummary && row.parentId === null) {
        // Check if it's a main ingredient
        const startRow = currentRow;
        const concentration =
          parseFloat(concentrations[row.id]?.concentration) || 0;

        // Add the main ingredient
        const mainIngredientRow = {
          "No.": row.order || "",
          "SAP Code": row.sap_code || "",
          "Trade Name": row.trade_name || "",
          "INCI Name": row.inci_name || "", // Set INCI name for ingredients without sub-ingredients
          "EINECS NO": row.einecs_no || "",
          "CAS No.": row.cas_no || "",
          "% active in raw material/trade name": row.percent_active || "",
          Manufacturer: row.manufacturer || "",
          "Country of Origin": row.country_of_origin || "",
          REACH: row.reach || "",
          "Concentration on formula in %": concentration.toFixed(5),
          "Concentration in end formula": concentration.toFixed(4), // Set end formula concentration for ingredients without sub-ingredients
          Function: row.function || "",
          Restrictions: row.restrictions || "",
          Status: row.status || "",
          Price: row.price || "",
          Vegan: row.vegan || "",
          "ISO 16128 Natural Origin Ingredient":
            row.iso_16128_natural_origin_ingredient || "",
          Supplier: row.supplier || "",
          "No Animal Testing Declaration":
            row.no_animal_testing_declaration || "",
          "SDS (Validity 3 Years)": row.sds_validity_3_years || "",
          Organic: row.organic || "",
          "COSMOS Certification": row.cosmos_certification || "",
          "Gluten Free": row.gluten_free || "",
          GMO: row.gmo || "",
          Microplastic: row.microplastic || "",
          RSPO: row.rspo || "",
          "RSPO №": row.rspo_no || "",
          Fairtrade: row.fairtrade || "",
          "Origin of Raw Material": row.origin_of_raw_material || "",
          "Microbiological Analysis": row.microbiological_analysis || "",
          Solubility: row.solubility || "",
        };

        // Add sub-ingredients
        if (row.subingredients && row.subingredients.length > 0) {
          row.subingredients.forEach((sub, index) => {
            const subConcentration = calculateEndFormulaConcentration(
              sub,
              concentration
            );
            if (index === 0) {
              // For the first sub-ingredient, merge it with the main ingredient row
              mainIngredientRow["INCI Name"] = sub.inci_name || "";
              mainIngredientRow["EINECS NO"] = sub.einecs_no || "";
              mainIngredientRow["CAS No."] = sub.cas_no || "";
              mainIngredientRow["% active in raw material/trade name"] =
                sub.percent_active || "";
              mainIngredientRow["Concentration in end formula"] =
                subConcentration;
              worksheetData.push(mainIngredientRow);
            } else {
              // For subsequent sub-ingredients
              worksheetData.push({
                "No.": "",
                "SAP Code": "",
                "Trade Name": "",
                "INCI Name": sub.inci_name || "",
                "EINECS NO": sub.einecs_no || "",
                "CAS No.": sub.cas_no || "",
                "% active in raw material/trade name": sub.percent_active || "",
                Manufacturer: "",
                "Country of Origin": "",
                REACH: sub.reach || "",
                "Concentration on formula in %": "",
                "Concentration in end formula": subConcentration,
                Function: "",
                Restrictions: "",
                Status: "",
                Price: "",
                Vegan: "",
                "ISO 16128 Natural Origin Ingredient": "",
                Supplier: "",
                "No Animal Testing Declaration": "",
                "SDS (Validity 3 Years)": "",
                Organic: "",
                "COSMOS Certification": "",
                "Gluten Free": "",
                GMO: "",
                Microplastic: "",
                RSPO: "",
                "RSPO №": "",
                Fairtrade: "",
                "Origin of Raw Material": "",
                "Microbiological Analysis": "",
                Solubility: "",
              });
            }
            currentRow++;
          });

          // Merge cells for the main ingredient
          [
            "No.",
            "SAP Code",
            "Trade Name",
            "Manufacturer",
            "Country of Origin",
            "Concentration on formula in %",
            "Function",
            "Restrictions",
            "Status",
            "Price",
            "Vegan",
            "ISO 16128 Natural Origin Ingredient",
            "Supplier",
            "No Animal Testing Declaration",
            "SDS (Validity 3 Years)",
            "Organic",
            "COSMOS Certification",
            "Gluten Free",
            "GMO",
            "Microplastic",
            "RSPO",
            "RSPO №",
            "Fairtrade",
            "Origin of Raw Material",
            "Microbiological Analysis",
            "Solubility",
          ].forEach((field) => {
            merge.push({
              s: {
                r: startRow,
                c: Object.keys(mainIngredientRow).indexOf(field),
              },
              e: {
                r: currentRow - 1,
                c: Object.keys(mainIngredientRow).indexOf(field),
              },
            });
          });
        } else {
          // If there are no sub-ingredients, just add the main ingredient row
          worksheetData.push(mainIngredientRow);
          currentRow++;
        }
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);

    // Apply merges
    worksheet["!merges"] = merge;

    // Set column widths
    worksheet["!cols"] = [
      { wch: 5 }, // No.
      { wch: 20 }, // SAP Code
      { wch: 30 }, // Trade Name
      { wch: 50 }, // INCI Name
      { wch: 15 }, // EINECS NO
      { wch: 15 }, // CAS No.
      { wch: 30 }, // % active in raw material/trade name
      { wch: 20 }, // Manufacturer
      { wch: 20 }, // Country of Origin
      { wch: 20 }, // REACH
      { wch: 25 }, // Concentration on formula in %
      { wch: 25 }, // Concentration in end formula
      { wch: 20 }, // Function
      { wch: 20 }, // Restrictions
      { wch: 20 }, // Status
      { wch: 15 }, // Price
      { wch: 15 }, // Vegan
      { wch: 30 }, // ISO 16128 Natural Origin Ingredient
      { wch: 20 }, // Supplier
      { wch: 30 }, // No Animal Testing Declaration
      { wch: 25 }, // SDS (Validity 3 Years)
      { wch: 15 }, // Organic
      { wch: 25 }, // COSMOS Certification
      { wch: 15 }, // Gluten Free
      { wch: 15 }, // GMO
      { wch: 15 }, // Microplastic
      { wch: 15 }, // RSPO
      { wch: 15 }, // RSPO №
      { wch: 15 }, // Fairtrade
      { wch: 25 }, // Origin of Raw Material
      { wch: 25 }, // Microbiological Analysis
      { wch: 20 }, // Solubility
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Ingredients");
    XLSX.writeFile(workbook, "Ingredients_Export.xlsx");
  };

  // Custom Toolbar with Export Button
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Button onClick={handleExportToExcel} startIcon={<DownloadIcon />}>
        Export to Excel
      </Button>
      <Button
        startIcon={<Share />}
        onClick={handleGeneratePublicUrl}
        sx={{ marginLeft: 1 }}
      >
        Share
      </Button>
    </GridToolbarContainer>
  );

  const CustomFooter = ({ totalConcentration }) => {
    return (
      <GridFooterContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            backgroundColor: "rgb(36, 41, 46)",
            color: theme.palette.primary.contrastText,
            padding: 1,
          }}
        >
          {/* This part will style the "Total Rows: X" */}
          <GridFooter
            sx={{
              "& .MuiTablePagination-root": {
                color: theme.palette.primary.contrastText,
              },
              "& .MuiTablePagination-caption": {
                fontWeight: "bold",
              },
            }}
          />

          {/* This is custom footer content */}
          <Typography variant="body1" fontWeight="bold">
            Total Concentration: {totalConcentration.toFixed(2)}%
          </Typography>
        </Box>
      </GridFooterContainer>
    );
  };

  const groupingColDef = {
    headerName: "",
    width: 50,
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
<Box
      sx={{
        flexGrow: 1,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        width: "100%",
      }}
    >
        <Autocomplete
          freeSolo
          options={searchResults}
          getOptionLabel={(option) => option.trade_name || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Ingredients"
              variant="outlined"
              onChange={(event) => handleSearch(event, event.target.value)}
              sx={{ width: "100%" }}
            />
          )}
          onChange={handleAddIngredient}
          loading={searchLoading}
          sx={{ width: "100%" }}
          PaperComponent={(props) => (
            <Paper
              {...props}
              sx={{
                width: "100%",
                boxSizing: "border-box",
              }}
            />
          )}
          PopperComponent={(props) => {
            const { anchorEl, ...popperProps } = props;
            return (
              <Popper
                {...popperProps}
                anchorEl={anchorEl}
                sx={{
                  width: anchorEl ? anchorEl.clientWidth : undefined,
                }}
                placement="bottom-start"
                modifiers={[
                  {
                    name: "flip",
                    enabled: true,
                  },
                  {
                    name: "preventOverflow",
                    options: {
                      altAxis: true,
                      boundary: "viewport",
                    },
                  },
                ]}
              />
            );
          }}
        />

        {errorMessage && (
          <Typography
            color="error"
            variant="body2"
            sx={{ width: "100%", textAlign: "center" }}
          >
            {errorMessage}
          </Typography>
        )}

        <Box
          sx={{
            height: "calc(100vh - 100px)",
            width: "100%",
            mt: 2,
          }}
        >
          <DataGridPro
            key={renderKey}
            rows={treeData}
            columns={columns}
            treeData
            getTreeDataPath={getTreeDataPath}
            disableColumnReorder
            slots={{
              toolbar: () => <CustomToolbar data={treeData} />,
              footer: CustomFooter,
            }}
            slotProps={{
              footer: { totalConcentration },
            }}
            groupingColDef={groupingColDef}
            sx={{ height: "100%", width: "100%" }}
          />
        </Box>

        {inciNames.length > 0 && (
          <Box
            sx={{
              width: "100%",
              mt: 4,
              p: 2,
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              INCI Names
            </Typography>
            <ul>
              {inciNames.map((item, index) => (
                <li key={index}>
                  {item.inciName} - {item.concentration}%
                </li>
              ))}
            </ul>
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: 2,
          }}
        >
          <ButtonGroup
            sx={{ gap: "5px", display: "flex", justifyContent: "center" }}
          >
            {isEditing && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  handleSaveFormula(editingFolderId, formulaName, true)
                }
              >
                Update Formula
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(true)}
            >
              Save Formula
            </Button>
          </ButtonGroup>
        </Box>

        <FolderModule
          open={openModal}
          onClose={() => setOpenModal(false)}
          folders={folders}
          onSave={(folderId, formulaName) =>
            handleSaveFormula(folderId, formulaName, isEditing ? false : true)
          }
          refreshFolders={fetchAllData}
          isEditing={isEditing}
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          action={
            publicUrl && (
              <Button
                color="customColor"
                size="small"
                onClick={() => navigator.clipboard.writeText(publicUrl)}
              >
                Copy
              </Button>
            )
          }
        />
      </Box>
  );
};
export default IngredientsList;
