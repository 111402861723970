// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#24292e',
    },
    secondary: {
      main: 'rgb(78, 54, 245)', 
      color: '#fff',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#29b6f6',
    },
    success: {
      main: '#66bb6a',
    },
    customColor: {
      main: '#ff4081',
      contrastText: '#fff',
    },
  },
  typography: {
    button: {
      borderBottomColor: 'rgb(255, 255, 255)'
    },
  },
});

export default theme;
