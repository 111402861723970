import { Typography, Button, Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';

const Home = () => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser && currentUser.username) {
      setUsername(currentUser.username);
    }
  }, []);

  const handleCreateNewFormula = () => {
    console.log("Navigating to create new formula");
    navigate('/ingredients', { state: { isNewFormula: true } });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="70vh"
      padding={3}
    >
      <Paper 
        elevation={3} 
        sx={{ padding: 4, textAlign: 'center', maxWidth: 400, borderRadius: '8px', boxShadow:'dark' }}
      >
        <Typography variant="h4" gutterBottom>
          Welcome {username}!
        </Typography>
        <Typography variant="h5"> What would you like to do next?</Typography>
        <Box 
          display="flex" 
          flexDirection="column" 
          gap={2}
          marginTop={2}
        >
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCreateNewFormula}
          >
            Create New Formula
          </Button>
          <Button 
            variant="contained" 
            color="secondary" 
            component={Link}  
            to="/addingredient" 
          >
            Add New Ingredients
          </Button>
          <Button 
          variant="outlined" 
          color="secondary"
          component={Link} 
          to="/uploadingredients"
        >
          Upload Ingredients from Excel
        </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Home;
