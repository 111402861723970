import React, { useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Snackbar,
  Alert
} from "@mui/material";
import axios from "axios";
import AuthService from "../services/auth.service";
import config from "../config";

const api = axios.create({
  baseURL: config.API_URL,
});

const AddIngredient = ({ refreshIngredients }) => {
  const [ingredientData, setIngredientData] = useState({
    cas_no: "",
    country_of_origin: "",
    einecs_no: "",
    function: "",
    inci_name: "",
    manufacturer: "",
    percent_active: "",
    reach: "",
    sap_code: "",
    trade_name: "",
    restrictions: "",
    status: "",
    price: "",
    vegan: "",
    iso_16128_natural_origin_ingredient: "",
    supplier: "",
    no_animal_testing_declaration: "",
    sds_validity_3_years: "",
    organic: "",
    cosmos_certification: "",
    gluten_free: "",
    gmo: "",
    microplastic: "",
    rspo: "",
    rspo_no: "",
    fairtrade: "",
    origin_of_raw_material: "",
    microbiological_analysis: "",
    solubility: "",
  });

  const [hasSubIngredients, setHasSubIngredients] = useState(false);
  const [subIngredients, setSubIngredients] = useState([]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Handle input changes for the main ingredient
  const handleIngredientChange = (e) => {
    const { name, value } = e.target;
    setIngredientData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle adding a new sub-ingredient
  const addSubIngredient = () => {
    setSubIngredients([
      ...subIngredients,
      {
        cas_no: "",
        einecs_no: "",
        inci_name: "",
        percent_active: "",
        restrictions: "",
        origin_of_raw_material: "",
        function: "",
      },
    ]);
  };

  // Handle input changes for sub-ingredients
  const handleSubIngredientChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSubIngredients = [...subIngredients];
    updatedSubIngredients[index][name] = value;
    setSubIngredients(updatedSubIngredients);
  };

  // Handle the form submission
  const handleSubmit = async () => {
    try {
      const token = AuthService.getCurrentUser().accessToken;
  
      // Ensure numeric fields are valid numbers or set to null
      const cleanedData = {
        ...ingredientData,
        percent_active: ingredientData.percent_active ? parseFloat(ingredientData.percent_active) : null,
        price: ingredientData.price ? parseFloat(ingredientData.price) : null,
        iso_16128_natural_origin_ingredient: ingredientData.iso_16128_natural_origin_ingredient
          ? parseFloat(ingredientData.iso_16128_natural_origin_ingredient)
          : null,
        // Check if the date is valid, otherwise set it to null
        sds_validity_3_years: ingredientData.sds_validity_3_years && ingredientData.sds_validity_3_years !== 'Invalid date'
          ? ingredientData.sds_validity_3_years
          : null,
        // Handle other numeric fields similarly if required
      };
  
      // First, save the main ingredient
      const ingredientResponse = await api.post(
        "/api/ingredients",
        cleanedData,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
  
      const ingredientId = ingredientResponse.data.id;
  
      // If there are sub-ingredients, save them
      if (hasSubIngredients && subIngredients.length > 0) {
        const subIngredientsData = subIngredients.map((sub) => ({
          ...sub,
          ingredient_id: ingredientId, // Link sub-ingredients to the main ingredient
          percent_active: sub.percent_active ? parseFloat(sub.percent_active) : null,
        }));
  
        // Save each sub-ingredient individually
        for (const subIngredient of subIngredientsData) {
          await api.post(
            "/api/ingredients/subingredients",
            subIngredient,
            {
              headers: {
                "x-access-token": token,
              },
            }
          );
        }
      }  
      // Refresh the ingredients list in the parent component if needed
      if (refreshIngredients) {
        refreshIngredients();
      }
      setSnackbar({
        open: true,
        message: "Ingredient saved successfully!",
        severity: "success",
      });
  
      // Reset the form
      setIngredientData({
        cas_no: "",
        country_of_origin: "",
        einecs_no: "",
        function: "",
        inci_name: "",
        manufacturer: "",
        percent_active: "",
        reach: "",
        sap_code: "",
        trade_name: "",
        restrictions: "",
        status: "",
        price: "",
        vegan: "",
        iso_16128_natural_origin_ingredient: "",
        supplier: "",
        no_animal_testing_declaration: "",
        sds_validity_3_years: "",
        organic: "",
        cosmos_certification: "",
        gluten_free: "",
        gmo: "",
        microplastic: "",
        rspo: "",
        rspo_no: "",
        fairtrade: "",
        origin_of_raw_material: "",
        microbiological_analysis: "",
        solubility: "",
      });
      setSubIngredients([]);
      setHasSubIngredients(false);
    } catch (error) {
      console.error("Error adding ingredient:", error);
      setSnackbar({
        open: true,
        message: "Failed to add ingredient. Please try again.",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  
  
  return (
    <Box sx={{ p: 3, border: "1px solid #ccc", borderRadius: "8px" }}>
      <Typography variant="h6" gutterBottom>
        Add New Ingredient
      </Typography>

      {/* Main ingredient fields */}
      <TextField
        fullWidth
        label="SAP Code"
        name="sap_code"
        value={ingredientData.sap_code}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Trade Name"
        name="trade_name"
        value={ingredientData.trade_name}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="INCI Name"
        name="inci_name"
        value={ingredientData.inci_name}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="CAS No"
        name="cas_no"
        value={ingredientData.cas_no}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="EINECS No"
        name="einecs_no"
        value={ingredientData.einecs_no}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Country of Origin"
        name="country_of_origin"
        value={ingredientData.country_of_origin}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Function"
        name="function"
        value={ingredientData.function}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Manufacturer"
        name="manufacturer"
        value={ingredientData.manufacturer}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Supplier"
        name="supplier"
        value={ingredientData.supplier}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Percent Active"
        name="percent_active"
        value={ingredientData.percent_active}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="REACH"
        name="reach"
        value={ingredientData.reach}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Restrictions"
        name="restrictions"
        value={ingredientData.restrictions}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Status"
        name="status"
        value={ingredientData.status}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Price"
        name="price"
        type="number"
        value={ingredientData.price}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Vegan"
        name="vegan"
        value={ingredientData.vegan}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="ISO 16128 Natural Origin"
        name="iso_16128_natural_origin_ingredient"
        type="number"
        value={ingredientData.iso_16128_natural_origin_ingredient}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="No Animal Testing"
        name="no_animal_testing_declaration"
        value={ingredientData.no_animal_testing_declaration}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="SDS Validity"
        name="sds_validity_3_years"
        type="date"
        value={ingredientData.sds_validity_3_years}
        onChange={handleIngredientChange}
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        label="Organic"
        name="organic"
        value={ingredientData.organic}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="COSMOS Certification"
        name="cosmos_certification"
        value={ingredientData.cosmos_certification}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Gluten Free"
        name="gluten_free"
        value={ingredientData.gluten_free}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="GMO"
        name="gmo"
        value={ingredientData.gmo}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Microplastic"
        name="microplastic"
        value={ingredientData.microplastic}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="RSPO"
        name="rspo"
        value={ingredientData.rspo}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="RSPO Number"
        name="rspo_no" 
        value={ingredientData.rspo_no}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Fairtrade"
        name="fairtrade"
        value={ingredientData.fairtrade}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Origin of Raw Material"
        name="origin_of_raw_material"
        value={ingredientData.origin_of_raw_material}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Microbiological Analysis"
        name="microbiological_analysis"
        value={ingredientData.microbiological_analysis}
        onChange={handleIngredientChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Solubility"
        name="solubility"
        value={ingredientData.solubility}
        onChange={handleIngredientChange}
        margin="normal"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={hasSubIngredients}
            onChange={() => setHasSubIngredients(!hasSubIngredients)}
          />
        }
        label="This ingredient has sub-ingredients"
      />

      {hasSubIngredients && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Sub-Ingredients</Typography>
          {subIngredients.map((sub, index) => (
            <Box key={index} sx={{ mb: 2, p: 2, border: "1px solid #ccc" }}>
              <TextField
                fullWidth
                label="INCI Name"
                name="inci_name"
                value={sub.inci_name}
                onChange={(e) => handleSubIngredientChange(index, e)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="CAS No"
                name="cas_no"
                value={sub.cas_no}
                onChange={(e) => handleSubIngredientChange(index, e)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="EINECS No"
                name="einecs_no"
                value={sub.einecs_no}
                onChange={(e) => handleSubIngredientChange(index, e)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Percent Active"
                name="percent_active"
                value={sub.percent_active}
                onChange={(e) => handleSubIngredientChange(index, e)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Function"
                name="function"
                value={sub.function}
                onChange={(e) => handleSubIngredientChange(index, e)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Restrictions"
                name="restrictions"
                value={sub.restrictions}
                onChange={(e) => handleSubIngredientChange(index, e)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Origin of Raw Material"
                name="origin_of_raw_material"
                value={sub.origin_of_raw_material}
                onChange={(e) => handleSubIngredientChange(index, e)}
                margin="normal"
              />
            </Box>
          ))}
          <Button variant="contained" onClick={addSubIngredient}>
            Add Sub-Ingredient
          </Button>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ mt: 3 }}
      >
        Save Ingredient
      </Button>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddIngredient;
